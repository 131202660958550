.prototype-container {
	justify-content: center;
  	align-items: center;
  	text-align: center;
}

.prototype-container-center {
	justify-content: center;
  	align-items: center;
  	text-align: center;

  	display: flex;
  	flex-direction: column;
  	align-items: center;
  	width: 100%;
  	position: relative;

}

.float-left-child {
 	justify-content: center;
  	align-items: center;
  	text-align: center;
  	background: #693668;
  	height: 150px;
} 

.float-center-child {
 	justify-content: center;
  	align-items: center;
  	text-align: center;
  	height: 150px;
  	display: flex;
  	flex-direction: column;
  	justify-content: center;
  	align-items: center;
} 

.text-input {
	width: 600px;
}

.float-inner-child {
  	
} 

.float-center {
  	border-style: dotted;
  	width: 600px;
  	height: 150px;
  	vertical-align: middle;
} 


.float-alert-left {
    width: 30%;
    float: left;
    padding: 10px;
    margin: -10px;
} 

.float-alert-right {
    width: 70%;
    float: right;
    vertical-align: middle;
    padding: 40px;
    margin: -10px;
} 