.body-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.float-container {
    padding: 20px;
}

.float-left-child {
	display: flex;
 	justify-content: center;
  	align-items: center;
  	text-align: center;  
}

.float-left-child-main {
	display: flex;
 	justify-content: center;
  	align-items: center;
  	text-align: center;  
} 

.float-right-child {
    width: 50%;
    float: right;
    padding: 0px;
    margin: -10px;
} 

.text-input {
	background-color: lightblue;
	width: 300px;
	padding:10px;
  	border: 3px solid #555;
  	box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
}

.btn {
	background-color: #04AA6D;
	border: none;
	color: white;
	padding: 5px 5px;
	text-decoration: none;
	margin: 4px 2px;
	cursor: pointer;
}