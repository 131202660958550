.explore-float-left-child {
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #693668;
    height: 300px;
} 

.logo {
    background: #ffffff;
    float: left;
    border-width: 20px;
    width: 100%;
} 

.float-inner-container {
    width: 33%;
    background: #ffffff;
    float: left;
    border-style: solid;
    border-color: #693668;
    border-width: 5px;
} 

.float-bottom-child {
  justify-content: center;
    align-items: center;
    text-align: center;
    background: #ffffff;
    height: 300px;
} 

.float-bottom-inner-container {
    width: 33%;
    background: #cccccc;
    float: left;
    border-style: solid;
    border-color: #ffffff;
    border-width: 5px;
    height: 200px;
} 